import {TREE} from "@/app/menu-tree";
import {ExtEnum, safeAccess} from "@/calico-vue/service";
import {authInfo} from "@/lib/service";
import sugarClone from "sugar/object/clone";
import add from "sugar/array/add";

let fullMenuTree = null;
let accessibleMenuTree = null;
let menuTreeForSideMenu = null;

export const MenuService = {
  /**
   * メニューツリー取得
   */
  getFullMenuTree(clone = false) {
    if (fullMenuTree == null) {
      fullMenuTree = cloneNodes(TREE);
      bindMenuExtEnum(fullMenuTree);
    }
    return clone ? cloneNodes(fullMenuTree) : fullMenuTree;
  },
  getAccessibleMenuTree(clone = false) {
    if (accessibleMenuTree == null) {
      accessibleMenuTree = this.getFullMenuTree(true);
      accessibleMenuTree = filterOnlyAccessible(accessibleMenuTree);
    }
    return clone ? cloneNodes(accessibleMenuTree) : accessibleMenuTree;
  },
  getMenuTreeForSideMenu(route) {
    if (menuTreeForSideMenu == null) {
      menuTreeForSideMenu = this.getAccessibleMenuTree(true);
      prepareOpened(menuTreeForSideMenu, route);
    }
    return menuTreeForSideMenu;
  },
  clearCache() {
    fullMenuTree = null;
    accessibleMenuTree = null;
    menuTreeForSideMenu = null;
  },
};

function cloneNodes(nodes) {
  return nodes.map((e) => sugarClone(e, true));
}

function bindMenuExtEnum(nodes) {
  nodes.forEach((node) => {
    if (node.menuId != null) {
      node.menu = ExtEnum.UserAuthority.values().find(
        (a) => a.id === node.menuId
      );
    }
    if (node.children != null) {
      bindMenuExtEnum(node.children);
    }
  });
}

function filterOnlyAccessible(nodes) {
  return nodes.filter((node) => {
    if (node.children == null && node.requiredAuthorities != null) {
      return authInfo.isAccessible(node.requiredAuthorities);
    } else if (node.children != null) {
      node.children = filterOnlyAccessible(node.children);
      return node.children.length !== 0;
    }
    return false;
  });
}

function prepareOpened(nodes, route) {
  setOpenedToFalse(nodes);

  let menuId = safeAccess(route, "meta.menuId");
  if (menuId != null) {
    selectShouldOpenedNodes(nodes, menuId).forEach((node) => {
      node.opened = true;
    });
  }
}
function setOpenedToFalse(nodes) {
  nodes.forEach((node) => {
    if (node.children != null) {
      node.opened = false;
      prepareOpened(node.children);
    }
  });
}
function selectShouldOpenedNodes(nodes, menuId, buf = []) {
  for (const node of nodes) {
    if (node.menuId === menuId) {
      return buf;
    } else if (node.children != null) {
      let ret = selectShouldOpenedNodes(node.children, menuId, add(buf, node));
      if (ret.length !== 0) {
        return ret;
      }
    }
  }
  return [];
}
