import VueRouter from "vue-router";
import * as appRoutes from "@/app/routes";
import NotFound from "@/app/NotFound";
import * as Init from "@/calico-vue/init";
import {announce, Api} from "@/calico-vue/service";
import {authInfo, AuthService} from "@/lib/service";
import {getRequiredAuthorities} from "@/app/menu-tree";

/**
 * routes定義
 */
let routes = [];
console.log(appRoutes);
for (const key in appRoutes) {
  routes.push(...appRoutes[key]);
}
routes.push({
  path: "*",
  component: NotFound,
  meta: { allowAll: true },
});

/**
 * VueRouter初期化
 */
export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path === from.path) {
      return false;
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

/**
 * keep
 * 認証情報の最新化とセッション時間延長
 */
router.beforeEach((to, from, next) => {
  AuthService.keep().then(
    () => {
      next();
    },
    () => {}
  );
});

/**
 * ログインチェック
 */
router.beforeEach((to, from, next) => {
  const allowAll = to.matched.every((route) => route.meta.allowAll);
  if (allowAll || authInfo.authenticated) {
    next();
  } else {
    next({ path: "/login" });
  }
});

/**
 * load extEnum
 * ログインしてない場合はExtEnumを取得しない
 */
const extEnumLoader = () =>
  new Api()
    .execute("/system/extenum/get_all", null, false)
    .then((res) => res.data);
  router.beforeEach((to, from, next) => {
  if (authInfo.authenticated) {
    Init.loadExtEnum(extEnumLoader).then(
      () => {
        next();
      },
      () => {}
    );
  } else {
    next();
  }
});

/**
 * 権限チェック
 */
router.beforeEach((to, from, next) => {
  const menuId = to.meta.menuId;
  if (menuId != null && !authInfo.isAccessible(getRequiredAuthorities(menuId))) {
    announce.error("権限のない画面にアクセスしようとしました。");
    next({ path: "/home/index" });
    return;
  }

  const userType = to.meta.userType;
  if (userType != null && authInfo.userType?.id !== userType) {
    announce.error("権限のない画面にアクセスしようとしました。");
    next({ path: "/home/index" });
    return;
  }

  next();
});
