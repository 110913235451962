<template>
  <nav v-if="menuTree" class="menu-nav">
    <ul v-for="(node, index) in menuTree" :key="index">
      <template v-if="!node.hidden">
        <li v-if="node.menuId == null" @click="node.opened = !node.opened" class="division">
          <span class="material-icons" aria-hidden="true">{{node.icon}}</span><span v-html="node.name.replace('\n','<br>')"></span>
        </li>
        <li v-if="node.menuId != null && node.children == null" class="division" :class="{ active: node.menuId == menuId }">
          <span class="material-icons" aria-hidden="true">{{node.icon}}</span>
          <a @click="$move(node.basePath, $event)"><span v-html="node.name.replace('\n','<br>')"></span></a>
        </li>
        <li v-for="(node, index) in node.children" :key="index" >
          <a v-if="!node.hidden" @click="$move(node.basePath, $event)"><span v-html="node.name.replace('\n','<br>')"></span></a>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
import {MenuService} from '@/lib/service'
import {safeAccess} from '@/calico-vue/service'

export default {
  components: {
  },
  data() {
    return {
      menuTree: null,
    }
  },
  computed: {
    menuId: vm => safeAccess(vm.$route, 'meta.menuId')
  },
  created() {
    this.menuTree = MenuService.getMenuTreeForSideMenu(this.$route);
  },
}
</script>
