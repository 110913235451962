import {Api, RouteConfigBuilder} from "@/calico-vue/service";

const basePath = "/form";
const menuId = "jet.cbt.op.exam.form";

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: 'management' */ "./Index");
const Detail = () => import(/* webpackChunkName: 'management' */ "./Detail");

const Routes = new RouteConfigBuilder(basePath, {
  title: "試験問題",
  menuId
});

export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
  Routes.component("detail", Detail),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  getSearchForm() {
    return api.execute("get_search_form");
  },
  search(form) {
    return api.execute("search", form);
  },
  fetchExamFormGroup(examFormGroupId) {
    return api.execute("fetch_exam_form_group", {examFormGroupId});
  },
  addExamFormGroup(examFormGroupCode) {
    return api.execute("add_exam_form_group", {examFormGroupCode});
  },
  uploadForm(examFormId, file) {
    return api.execute("upload_form", {examFormId, file});
  },
  uploadAnswerForm(examFormGroupId, file) {
    return api.execute("upload_answer_form", {examFormGroupId, file});
  },
  uploadConverterForm(examFormGroupId, file) {
    return api.execute("upload_conversion_form", {examFormGroupId, file});
  },
  deleteExamFormGroup(examFormGroupId) {
    return api.execute("delete_exam_form_group", {examFormGroupId});
  },
};
