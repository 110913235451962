import {Api, RouteConfigBuilder} from "@/calico-vue/service";

const basePath = '/org/ta';
const menuId = "jet.cbt.op.org.ta";

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: 'management' */ "./Index");
const Detail = () => import(/* webpackChunkName: 'management' */ "./Detail");

const Routes = new RouteConfigBuilder(basePath, {
  title: "試験管理者",
  menuId
});
export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
  Routes.component("detail", Detail)
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  getSearchForm() {
    return api.execute("get_search_form");
  },
  search(form) {
    return api.execute("search", form);
  },
  fetchTestAdmin(userAccountId) {
    return api.execute("fetch_test_admin", {userAccountId});
  },
  selectSites(userAccountId) {
    return api.execute("select_sites", {userAccountId});
  },
  addTestAdmin(email, name) {
    return api.execute("add_test_admin", {email, name});
  },
  updateTestAdmin(userAccountId, name) {
    return api.execute("update_test_admin", {userAccountId, name});
  },
  deleteTestAdmin(userAccountId) {
    return api.execute("delete_test_admin", {userAccountId});
  },
  addSite(userAccountId, siteCode) {
    return api.execute("add_site", {userAccountId, siteCode});
  },
  deleteSite(userAccountId, siteId) {
    return api.execute("delete_site", {userAccountId, siteId});
  },
  updateTestAdminsInBulk(media) {
    return api.execute("update_test_admins_in_bulk", {media});
  },
  downloadSample() {
    return api.execute("download_sample");
  },
  sendRegistrationMail(userAccountId) {
    return api.execute("send_registration_mail", {userAccountId});
  }
};
