<template>
  <div>
    <MainHeader :no-auth="noAuth"/>
    <div class="col-xs-12">
      <div class="container mb-3">
        <div class="col-sm-3 col-md-2 sidebar" v-if="!noAuth">
          <SideMenu />
        </div>
        <div v-if="!noAuth" class="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <PageHeader/>
          <slot></slot>
        </div>
        <div v-else class="col-sm-12 col-md-12 main">
          <slot></slot>
        </div>
      </div>
      <footer>
        <div class="inner">
          <p>© 2022 JET Committee.</p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import MainHeader from './elements/MainHeader';
import PageHeader from './elements/PageHeader';
import SideMenu from './elements/SideMenu';

export default {
  components: {
    MainHeader,
    PageHeader,
    SideMenu,
  },
  props: {
    noAuth: { type: Boolean, default: false }
  },
  computed: {
    mainStyles: vm => {
      return {
        paddingLeft: vm.showAsideLeft ? '300px' : '0',
        minHeight: vm.mainHeight,
      }
    },
    asideLeftStyles: vm => {
      return {
        left: vm.showAsideLeft ? '0' : '-300px'
      }
    }
  },
  data(){
    return {
      showAsideLeft: initShowAsideLeft(),
      mainHeight: calcMainHeight(),
    };
  },
  methods: {
    toggleAsideLeft(){
      this.showAsideLeft = !this.showAsideLeft;
      saveShowAsideLeft(this.showAsideLeft);
    },
    updateMainHeight(){
      this.mainHeight = calcMainHeight();
    },
  },
  mounted: function () {
    window.addEventListener('resize', this.updateMainHeight);
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateMainHeight);
  },
}

/**
 * Cookieにサイドメニューの表示/非表示を記憶
 */
const SHOW_ASIDE_LEFT_COOKIE_KEY = 'show-aside-left';
const SHOW_ASIDE_LEFT_COOKIE_PATH = '/';

function initShowAsideLeft(){
  let cookies = document.cookie || '';
  if(cookies.match(SHOW_ASIDE_LEFT_COOKIE_KEY + '=true') !== null){
    return true;
  }else if(cookies.match(SHOW_ASIDE_LEFT_COOKIE_KEY + '=false') !== null){
    return false;
  }
  return true;
}
function saveShowAsideLeft(value){
  document.cookie = SHOW_ASIDE_LEFT_COOKIE_KEY + '=' + value + '; path=' + SHOW_ASIDE_LEFT_COOKIE_PATH;
}

/**
 * メイン領域height計算
 */
function calcMainHeight(){
  return (window.innerHeight - 30) + 'px';
}
</script>
