import {Api, RouteConfigBuilder} from "@/calico-vue/service";

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: 'management' */ "./Index");

const Routes = new RouteConfigBuilder();
export const routes = [
  Routes.component("/", Index),
];

const api = new Api("/system");

export const Service = {
  isOrgAdmin() {
    return api.execute("is_org_admin");
  },
}
