import { Api, RouteConfigBuilder } from "@/calico-vue/service";

const basePath = "/exam";
const menuId = "jet.cbt.op.exam.term";

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: 'management' */ "./Index");
const Detail = () => import(/* webpackChunkName: 'management' */ "./Detail");
const SendMail = () => import(/* webpackChunkName: 'management' */ "./SendMail");

const Routes = new RouteConfigBuilder(basePath, {
  title: "試験実施",
  menuId
});

export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
  Routes.component("detail", Detail),
  Routes.component("sendmail", SendMail),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  getSearchForm() {
    return api.execute("get_search_form");
  },
  search(form) {
    return api.execute("search", form);
  },
  fetchExamTerm(examTermId) {
    return api.execute("fetch_exam_term", {examTermId});
  },
  getOrganizations() {
    return api.execute("get_organizations");
  },
  addExamTerm(examTerm) {
    return api.execute("add_exam_term", examTerm);
  },
  updateExamTerm(examTerm) {
    return api.execute("update_exam_term", examTerm);
  },
  deleteExamTerm(examTermId) {
    return api.execute("delete_exam_term", {examTermId});
  },
  selectTaUserAccounts(examTermId) {
    return api.execute("select_ta_user_accounts", {examTermId});
  },
  getMailTemplate() {
    return api.execute("get_mail_template");
  },
  sendMail(mail) {
    return api.execute("send_ta_registration_mail", mail);
  },
  getExamSite(siteCode, examTermId) {
    return api.execute("get_exam_site", {siteCode: siteCode, examTermId: examTermId})
  },
  updateExamSite(examSite) {
    return api.execute("update_exam_site", examSite)
  },
  downloadExamAnswers(examTermId) {
    return api.execute("exam_answers_csv", {examTermId});
  },
  downloadExamineeCorrections(examTermId) {
    return api.execute("examinee_corrections_csv", {examTermId});
  },
  downloadExaminees(examTermId) {
    return api.execute("examinees_csv", {examTermId});
  }
};
