import {Api, RouteConfigBuilder} from "@/calico-vue/service";

const basePath = "/op";
const menuId = "jet.cbt.op.op";

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: 'management' */ "./Index");
const Detail = () => import(/* webpackChunkName: 'management' */ "./Detail");

const Routes = new RouteConfigBuilder(basePath, {
  title: "事務局管理者",
  menuId
});
export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
  Routes.component("detail", Detail),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  getSearchForm() {
    return api.execute("get_search_form");
  },
  search(form) {
    return api.execute("search", form);
  },
  fetchUser(userAccountId) {
    return api.execute("fetch_user", {userAccountId});
  },
  unlockAccount(userAccountId) {
    return api.execute("unlock_account", {userAccountId})
  },
  addUser(email, name) {
    return api.execute("add_user", {email, name});
  },
  updateUser(userAccountId, name) {
    return api.execute("update_user", {userAccountId, name});
  },
  deleteUser(userAccountId) {
    return api.execute("delete_user", {userAccountId});
  },
  sendRegistrationMail(userAccountId) {
    return api.execute("send_registration_mail", {userAccountId})
  }
};
