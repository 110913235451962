<template>
  <header class="container">
    <div class="left">
      <img src="@/images/logo_white.png" width="100">
    </div>
    <div class="center">
      <h1>本部・運営</h1>
    </div>
    <div class="right" v-if="!noAuth">
      <div>
        <a role="button" target="_blank" href="/download/endpoint/system/manual">
          <span aria-hidden="true" class="material-icons">library_books</span> マニュアル
        </a>
      </div>
      <div>
        <span aria-hidden="true" class="material-icons">person</span>
        <span>{{$authInfo.userName}}</span>
        <a role="button" @click.prevent="logout">
          <span class="material-icons logout">logout</span>
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import {AuthService} from '@/lib/service';

export default {
  props: {
    noAuth: Boolean
  },
  methods: {
    logout () {
      AuthService.logout();
    }
  }
}
</script>

<style scoped lang="scss">


header {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  margin: 0.5rem auto;
  font-size: 1.4rem;
  color: white;
  @media screen and (max-width: 767px) {
    align-items: baseline;
  }

  h1 {
    font-family: 'M PLUS Rounded 1c';
    font-size: 3rem;
    font-weight: normal;
    color: #fff;
    margin-left: 2rem;
    margin-bottom: 20px;

    img {
      vertical-align: middle;
    }
  }

  .right {
    flex: auto;
    text-align: right;
    div {
      font-size: 2.2rem;
      display: inline-block;
      margin-left: 1rem;
    }
  }

  .material-icons {
    font-size: 3rem;
    vertical-align: -0.75rem;
    margin-left: 1rem;
  }

  a {
    color: #fff;
    &:hover {
      color: #333;
    }
  }
}
</style>
