import {Api, RouteConfigBuilder} from "@/calico-vue/service";

const basePath = "/org/exam";
const menuId = "jet.cbt.op.org.exam";

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: 'management' */ "./Index");
const Sites = () => import(/* webpackChunkName: 'management' */ "./Sites");
const Examinees = () => import(/* webpackChunkName: 'management' */ "./Examinees");

const Routes = new RouteConfigBuilder(basePath, {
  title: "試験結果",
  menuId
});

export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
  Routes.component("sites", Sites),
  Routes.component("examinees", Examinees),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  getSearchForm() {
    return api.execute("get_search_form");
  },
  search(form) {
    return api.execute("search", form);
  },
  fetchExamTerm(examTermId) {
    return api.execute("fetch_exam_term", {examTermId});
  },
  getSiteSearchForm() {
    return api.execute("site/get_search_form");
  },
  siteSearch(form) {
    return api.execute("site/search", form);
  },
  fetchSite(examTermId, siteId) {
    return api.execute("site/fetch_site", {examTermId, siteId});
  },
  selectExaminees(examTermId, siteId) {
    return api.execute("site/examinee/select_examinees", {examTermId, siteId});
  },
}
