import {Api, RouteConfigBuilder} from "@/calico-vue/service";

const basePath = "/org/examinee";
const menuId = "jet.cbt.op.org.examinee";

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: 'management' */ "./Index");
const Examinees = () => import(/* webpackChunkName: 'management' */ "./Examinees");

const Routes = new RouteConfigBuilder(basePath, {
  title: "受検者一覧",
  menuId
});

export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
  Routes.component("examinees", Examinees),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  getSearchForm() {
    return api.execute("get_search_form");
  },
  search(form) {
    return api.execute("search", form);
  },
  getExamineesSearchForm() {
    return api.execute("examinees/get_search_form");
  },
  examineesSearch(form) {
    return api.execute("examinees/search", form);
  },
  uploadForm(examTermId, file) {
    return api.execute("upload_form", {examTermId, file});
  },
  downloadSample() {
    return api.execute("download_sample");
  },
}
