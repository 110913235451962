import { announce, safeAccess } from "@/calico-vue/service";
import { AuthService } from "@/lib/service";

export const API_HANDLERS = {
  default: {
    then: [(res) => res.data],
    catch: [defaultErrorHandle],
    finally: [],
  },
  save: {
    then: (data) => {
      announce.saved();
      return data;
    },
  },
  delete: {
    then: (data) => {
      announce.deleted();
      return data;
    },
  },
  execute: {
    then: (data) => {
      announce.executed();
      return data;
    },
  },
};

/**
 * defaultErrorHandle
 */
function defaultErrorHandle(cause) {
  if (cause.response.status / 100 === 5) {
    announce.error("サーバエラーが発生しました。");
    throw cause;
  }

  const error = safeAccess(cause, "response.data");
  if (error == null) {
    announce.error("サーバと正しく通信できませんでした。");
    throw cause;
  }

  if (error.type === "SessionTimeoutException") {
    AuthService.onSessionTimeout();
  }

  announce.error(error.message);
  throw error;
}
