export const TREE = [
  {
    name: "試験実施",
    icon: "desktop_windows",
    menuId: "jet.cbt.op.exam.term",
    requiredAuthorities: ["jet.cbt.op.exam.read"],
    basePath: "/exam",
  },
  {
    name: "試験問題",
    icon: "import_contacts",
    menuId: "jet.cbt.op.exam.form",
    requiredAuthorities: ["jet.cbt.op.exam.read"],
    basePath: "/form",
  },
  {
    name: "試験会場",
    icon: "corporate_fare",
    menuId: "jet.cbt.op.site",
    requiredAuthorities: ["jet.cbt.op.site.read"],
    basePath: "/site",
  },
  {
    name: "試験管理者",
    icon: "badge",
    menuId: "jet.cbt.op.ta",
    requiredAuthorities: ["jet.cbt.op.ta.write"],
    basePath: "/ta",
  },
  //以下学研用
  {
    name: "試験結果",
    icon: "edit_square",
    menuId: "jet.cbt.op.org.exam",
    requiredAuthorities: ["jet.cbt.op.org.exam.read"],
    basePath: "/org/exam",
  },
  {
    name: "教室情報\n登録・確認",
    icon: "corporate_fare",
    menuId: "jet.cbt.op.org.site",
    requiredAuthorities: ["jet.cbt.op.org.site.read"],
    basePath: "/org/site",
  },
  {
    name: "試験管理者情報\n登録・確認",
    icon: "badge",
    menuId: "jet.cbt.op.org.ta",
    requiredAuthorities: ["jet.cbt.op.org.ta.write"],
    basePath: "/org/ta",
  },
  {
    name: "受検者一覧",
    icon: "group",
    menuId: "jet.cbt.op.org.examinee",
    requiredAuthorities: ["jet.cbt.op.org.examinee.write"],
    basePath: "/org/examinee",
  },
  //学研用ここまで
  {
    name: "事務局管理者",
    icon: "desktop_windows",
    menuId: "jet.cbt.op.op",
    requiredAuthorities: ["jet.cbt.op.op.read"],
    basePath: "/op",
  },
  {
    name: "パスワード変更",
    icon: "key",
    menuId: "jet.cbt.op.password",
    requiredAuthorities: ["jet.cbt.op.self.write"],
    basePath: "/useraccount/password",
  },
];

/**
 * basePath取得
 */
export function getBasePath(menuId) {
  if (basePathCache == null) {
    basePathCache = {};
    gatherBasePathToCache(TREE);
  }
  if (basePathCache[menuId] == null) {
    throw new Error("basePath for " + menuId + " is not defined");
  }
  return basePathCache[menuId];
}

// メニューIDからアイコンをもらう
export function getMenuIcon(menuId) {
  if (menuIconCache == null) {
    menuIconCache = {};
    const stack = [];
    gatherIconToCache(TREE, stack);
  }
  if (menuIconCache[menuId] == null) {
    throw new Error("icon for " + menuId + " is not defined ");
  }
  return menuIconCache[menuId];
}

// メニューIDから権限をもらう
export function getRequiredAuthorities(menuId) {
  if (authoritiesCache == null) {
    authoritiesCache = {};
    gatherAuthoritiesToCache(TREE);
  }
  if (authoritiesCache[menuId] == null) {
    return [];
  }
  return authoritiesCache[menuId];
}

let menuIconCache = null;

function gatherIconToCache(nodes, stack) {
  nodes.forEach((node) => {
    if (node.icon != null) {
      menuIconCache[node.menuId] = node.icon;
      if (node.children != null) {
        stack.push(node);
        gatherIconToCache(node.children, stack);
        stack.pop();
      }
    } else if (node.menuId != null) {
      for (let i = stack.length - 1; i >= 0; i--) {
        const n = stack[i];
        if (n.icon != null) {
          menuIconCache[node.menuId] = n.icon;
          break;
        }
      }
    }
  });
}

let basePathCache = null;

function gatherBasePathToCache(nodes) {
  nodes.forEach((node) => {
    if (node.menuId != null && node.basePath != null) {
      basePathCache[node.menuId] = node.basePath;
    }
    if (node.children != null) {
      gatherBasePathToCache(node.children);
    }
  });
}

let authoritiesCache = null;

function gatherAuthoritiesToCache(nodes) {
  nodes.forEach((node) => {
    if (node.menuId != null && node.requiredAuthorities != null) {
      authoritiesCache[node.menuId] = node.requiredAuthorities;
    }
    if (node.children != null) {
      gatherAuthoritiesToCache(node.children);
    }
  });
}
