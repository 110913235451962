import {Api, RouteConfigBuilder} from "@/calico-vue/service";

const basePath = '/org/site';
const menuId = "jet.cbt.op.org.site";

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: 'management' */ "./Index");
const Detail = () => import(/* webpackChunkName: 'management' */ "./Detail");

const Routes = new RouteConfigBuilder(basePath, {
  title: '教室',
  menuId
});
export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
  Routes.component("detail", Detail),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  getSearchForm() {
    return api.execute("get_search_form");
  },
  search(form) {
    return api.execute("search", form);
  },
  fetchSite(siteId) {
    return api.execute("fetch_site", {siteId});
  },
  selectTestAdmins(siteId) {
    return api.execute("select_test_admins", {siteId});
  },
  addSite(site) {
    return api.execute("add_site", site);
  },
  updateSite(site) {
    return api.execute("update_site", site);
  },
  deleteSite(siteId) {
    return api.execute("delete_site", {siteId});
  },
  deleteTestAdmin(siteId, userAccountId) {
    return api.execute("delete_test_admin", {siteId, userAccountId});
  },
  updateSitesInBulk(media) {
    return api.execute("update_sites_in_bulk", {media});
  },
  downloadSample() {
    return api.execute("download_sample");
  }
};
