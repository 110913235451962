import {authInfo} from "./AuthInfo";
import {Api, move} from "@/calico-vue/service";
import {router} from "@/router";
import {MenuService} from "@/lib/service";

const api = new Api("/system/auth");

export const AuthService = {
  login(form) {
    return api.execute("login", form).then((data) => {
      authInfo.setData(data);
      this.clearCache();
      move(router, "/");
      return data;
    });
  },
  logout() {
    return api.execute("logout").then((data) => {
      authInfo.setData(data);
      this.clearCache();
      move(router, "/login");
      return data;
    });
  },
  keep() {
    return api.execute("keep").then((data) => {
      authInfo.setData(data);
      return data;
    });
  },
  onSessionTimeout() {
    authInfo.setData({});
    this.clearCache();
    move(router, "/login");
  },
  clearCache() {
    MenuService.clearCache();
  },
};
