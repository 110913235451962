import {Api, RouteConfigBuilder} from "@/calico-vue/service";

const basePath = '/info';
const menuId = 'jet.cbt.op.info';

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: 'management' */ "./Index");

const Routes = new RouteConfigBuilder(basePath, {
  title: "お知らせ",
  menuId
});
export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  getSearchForm() {
    return api.execute("get_search_form");
  },
  search(form) {
    return api.execute("search", form);
  },
};
